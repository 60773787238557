<template>
  <div style="width: 100%">
    <v-row>
      <v-col cols="12" md="3">
        <h3>Filter by Year :</h3>
        <v-select
          dense
          hide-details
          :items="getYear"
          v-model="year"
          @change="getGrafis()"
          label="Filter"
          flat
          solo
        ></v-select>
      </v-col>
      <v-col cols="12" md="9">
        <h3>Filter by Phase :</h3>
        <v-select
          dense
          hide-details
          :items="phase"
          item-text="state"
          item-value="abbr"
          v-model="selectedPhase"
          label="Filter"
          flat
          solo
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'baseline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3" flat v-if="baseline">
          <div>
            <h1>Baseline</h1>
          </div>
          <div class="mx-5">
            <bar-chart
              height="350px"
              :min="0"
              :max="5"
              :data="baseline[0]"
              :colors="['#008448']"
              :dataset="{ borderWidth: 0 }"
            ></bar-chart>
          </div>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!baseline">
          <div>
            <h1>Baseline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'baseline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3" flat v-if="baseline">
          <div>
            <h1>Baseline</h1>
          </div>
          <div class="mx-5">
            <bar-chart
              height="350px"
              :min="0"
              :max="5"
              :data="baseline[1]"
              :colors="['#008448']"
              :dataset="{ borderWidth: 0 }"
            ></bar-chart>
          </div>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!baseline">
          <div>
            <h1>Baseline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'midline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3 rounded-max" flat v-if="midline">
          <h1>Midline</h1>
          <bar-chart
            height="350px"
            :min="0"
            :max="5"
            :data="midline[0]"
            :colors="['#008448']"
            :dataset="{ borderWidth: 0 }"
          ></bar-chart>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!midline">
          <div>
            <h1>Midline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'midline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3 rounded-max" flat v-if="midline">
          <h1>Midline</h1>
          <bar-chart
            height="350px"
            :min="0"
            :max="5"
            :data="midline[1]"
            :colors="['#008448']"
            :dataset="{ borderWidth: 0 }"
          ></bar-chart>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!midline">
          <div>
            <h1>Midline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'endline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3" flat v-if="endline">
          <h1>Endline</h1>
          <bar-chart
            height="350px"
            :min="0"
            :max="5"
            :data="endline[0]"
            :colors="['#008448']"
            :dataset="{ borderWidth: 0 }"
          ></bar-chart>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!endline">
          <div>
            <h1>Endline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-if="selectedPhase == 'endline' || selectedPhase == 'all'"
      >
        <v-card class="pa-3" flat v-if="endline">
          <h1>Endline</h1>
          <bar-chart
            height="350px"
            :min="0"
            :max="5"
            :data="endline[1]"
            :colors="['#008448']"
            :dataset="{ borderWidth: 0 }"
          ></bar-chart>
        </v-card>
        <v-card height="350px" class="pa-3" flat v-if="!endline">
          <div>
            <h1>Endline</h1>
          </div>
          <div
            class="d-flex align-center justify-center flex-column"
            style="height: 100%"
          >
            <img src="../../../assets/img/notFound.png" height="150px" alt="" />
            <h1>No Data</h1>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "dashboardAdmin",
  computed: {
    ...mapState({
      counter: (state) => state.user.userCounter,
      log: (state) => state.log.log,
    }),
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push(i);
      }
      return data;
    },
  },
  data() {
    return {
      loading: true,
      loadingCount: false,
      loadingLog: false,
      year: 2020,
      phase: [
        { state: "Baseline", abbr: "baseline" },
        { state: "Midline", abbr: "midline" },
        { state: "Endline", abbr: "endline" },
        { state: "All Phase", abbr: "all" },
      ],
      selectedPhase: "all",
      baseline: null,
      midline: null,
      endline: null,
    };
  },
  mounted() {
    this.getGrafis();
  },
  methods: {
    getGrafis() {
      this.$store
        .dispatch("grafis/assessmentInfografis", this.year)
        .then((data) => {
          let result = data.data;
          if (data.code == 200) {
            let arrKey = [];
            for (let key in result) {
              if (key === "baseline") {
                for (let item in result[key]) {
                  arrKey.push([item, result[key][item]]);
                }
                // eslint-disable-next-line no-unused-vars
                let index1 = arrKey.filter((el, idx) => {
                  return idx < 9;
                });
                // eslint-disable-next-line no-unused-vars
                let index2 = arrKey.filter((el, idx) => {
                  return idx > 8;
                });
                this.baseline = [index1, index2];
                arrKey = []

              }
              if (key === "midline") {
                for (let item in result[key]) {
                  arrKey.push([item, result[key][item]]);
                }
                // eslint-disable-next-line no-unused-vars
                let index1 = arrKey.filter((el, idx) => {
                  return idx < 9;
                });
                // eslint-disable-next-line no-unused-vars
                let index2 = arrKey.filter((el, idx) => {
                  return idx > 8 && idx <= 16;
                });
                this.midline = [index1, index2];
                arrKey = []
              }
              if (key === "endline") {
                for (let item in result[key]) {
                  arrKey.push([item, result[key][item]]);
                }
                // eslint-disable-next-line no-unused-vars
                let index1 = arrKey.filter((el, idx) => {
                  return idx < 9;
                });
                // eslint-disable-next-line no-unused-vars
                let index2 = arrKey.filter((el, idx) => {
                  return idx > 8 && idx <= 16;
                });
                this.endline = [index1, index2];
              }
            }
          } else if (data.code == 404) {
            let vm = this;
            vm.baseline = null;
            vm.midline = null;
            vm.endline = null;
          }
        });
    },
  },
};
</script>

<style scoped>
._summary {
  width: 60%;
}
.activity_ {
  width: 40%;
}
</style>
