<template>
  <div style="width: 100%" v-if="statistic">
    <div v-if="statistic.code !== 404">
      <div class="d-flex justify-content">
        <div class="my-progress d-flex justify-center">
          <v-card class="rounded-max pa-3 d-flex">
            <div class="d-flex align-center mr-5">
              <img
                src="../../../assets/img/IconScholar.png"
                height="35px"
                alt=""
              />
              <h3>Your Current Level</h3>
            </div>
            <v-card class="inset_ rounded-max py-4 mt-3" color="#F7F8F7">
              <section class="d-flex justify-center">
                <section class="d-flex justify-center" style="width: 500px">
                  <img
                    v-if="phase == 'endline'"
                    src="../../../assets/img/ScholarsLevelEndline.png"
                    height="40px"
                    alt=""
                  />
                  <img
                    v-else-if="phase == 'midline'"
                    src="../../../assets/img/Scholars LevelMidline.png"
                    height="40px"
                    alt=""
                  />
                  <img
                    v-else-if="phase == 'baseline'"
                    src="../../../assets/img/ScholarsLevelBaseline.png"
                    height="40px"
                    width="470px"
                    alt=""
                  />

                  <img
                    v-else
                    src="../../../assets/img/ScholarsLevelDefault.png"
                    height="40px"
                    alt=""
                  />
                </section>
              </section>
              <section class="d-flex justify-center">
                <section
                  class="d-flex justify-space-between px-3"
                  style="width: 500px"
                >
                  <div
                    :class="
                      phase == 'baseline'
                        ? `d-flex justify-center font-weight-black`
                        : `d-flex justify-center`
                    "
                  >
                    Baseline
                  </div>
                  <div
                    :class="
                      phase == 'midline'
                        ? `d-flex justify-center font-weight-black`
                        : `d-flex justify-center`
                    "
                  >
                    Midline
                  </div>
                  <div
                    :class="
                      phase == 'endline'
                        ? `d-flex justify-center font-weight-black`
                        : `d-flex justify-center`
                    "
                  >
                    Endline
                  </div>
                </section>
              </section>
            </v-card>
          </v-card>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="_summary round-radar">
          <v-card class="temp_radar pa-3 rounded-max">
            <div class="d-flex align-center mb-3">
              <img
                src="../../../assets/img/radarIcon.png"
                height="35px"
                alt=""
              />
              <h3>Values and Competency Score</h3>
            </div>
            <v-card
              v-if="!loading"
              height="500px"
              class="bg-radar inset_ rounded-max"
              elevation="0"
              width="80%"
              color="#F7F8F7"
            ></v-card>
            <div id="radar"></div>
          </v-card>
        </div>
      </div>
    </div>
    <div
      v-if="statistic.code == 404"
      class="mt-5 d-flex align-center justify-center flex-column"
    >
      <img src="../../../assets/img/notFound.png" height="200px" alt="" />
      <h1>{{ statistic.message }}</h1>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ApexCharts from "apexcharts";

export default {
  name: "dashboardScholar",
  computed: {
    ...mapState({
      profile: (state) => state.myProfile,
      counter: (state) => state.user.userCounter,
      log: (state) => state.log.log,
      statistic: (state) => state.grafis.statistic,
    }),
  },
  data() {
    return {
      loading: true,
      loadingCount: false,
      loadingLog: false,
      base: [],
      mid: [],
      end: [],
      categories: [],
      percent: null,
      labelBase: "",
      labelMid: "",
      labelEnd: "",
      topThree: null,
      phase: null,
    };
  },
  mounted() {
    this.$store.dispatch("getProfile").then((data) => {
      this.getGrafis(data.data.profile.id);
    });
  },
  methods: {
    getGrafis(id) {
      this.loading = true;
      this.$store.dispatch("grafis/staticScholar", id).then((data) => {
        this.phase = data.data.fase_scholar;
        let result = data.data;
        let baseline = result.baseline;
        let midline = result.midline;
        let endline = result.endline;
        this.topThree = result.top_3;
        this.percent = result.persen;
        for (let key in baseline) {
          if (key !== "periode") {
            this.base.push(baseline[key]);
            this.categories.push(key.replace(/_/g, " "));
          }
        }
        for (let key in midline) {
          if (key !== "periode") {
            this.mid.push(midline[key]);
            this.categories.push(key.replace(/_/g, " "));
          }
        }
        for (let key in endline) {
          if (key !== "periode") {
            this.end.push(endline[key]);
            this.categories.push(key.replace(/_/g, " "));
          }
        }
        setTimeout(() => {
          this.getRadar();
          this.loading = false;
        }, 100);
        setTimeout(() => {
          this.pieBaseline();
        }, 100);
        setTimeout(() => {
          this.pieMidline();
        }, 100);
        setTimeout(() => {
          this.pieEndline();
        }, 100);
      });
    },
    getRadar() {
      let series = [
        {
          name: "Baseline",
          data: this.base,
        },
        {
          name: "Midline",
          data: this.mid,
        },
        {
          name: "Endline",
          data: this.end,
        },
      ];
      let options = {
        series: series,

        colors: ["#008448", "#BCA14A", "#5F99AA"],
        chart: {
          height: 500,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "left",
          offsetY: 150,
          onItemClick: {
            toggleDataSeries: false,
          },
        },
        stroke: {
          width: 1,
        },
        fill: {
          opacity: 0.5,
        },
        markers: {
          size: 3,
          onClick: undefined,
        },
        xaxis: {
          categories: this.categories,
        },
        yaxis: [
          {
            tickAmount: 5,
            min: 0,
            max: 5,
          },
        ],
      };

      var chart = new ApexCharts(document.querySelector("#radar"), options);
      chart.render();
    },
    pieBaseline() {
      let actual = this.percent.baseline;
      let rounding = actual > 100 ? 100 : actual;
      let series = [100 - rounding, rounding];
      let color = "";
      if (actual <= 60) {
        color = "#92C3AC";
        this.labelBase = "Critical";
      } else if (actual > 60 && actual <= 75) {
        color = "#5FB48B";
        this.labelBase = "Intensive Development";
      } else if (actual > 75 && actual <= 90) {
        color = "#489D74";
        this.labelBase = "Near To Fit";
      } else if (actual > 90 && actual <= 100) {
        color = "#0D9859";
        this.labelBase = "Competency Fit";
      } else if (actual > 100) {
        color = "#008046";
        this.labelBase = "Above Standard";
      }
      var options = {
        series: series,
        chart: {
          width: 200,
          type: "pie",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        colors: ["#EDF1F0", color],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      };

      var chart = new ApexCharts(
        document.querySelector("#pieBaseline"),
        options
      );
      chart.render();
    },
    pieMidline() {
      let actual = this.percent.midline;
      let rounding = actual > 100 ? 100 : actual;
      let series = [100 - rounding, rounding];
      let color = "";
      if (actual <= 60) {
        color = "#92C3AC";
        this.labelMid = "Critical";
      } else if (actual > 60 && actual <= 75) {
        color = "#5FB48B";
        this.labelMid = "Intensive Development";
      } else if (actual > 75 && actual <= 90) {
        color = "#489D74";
        this.labelMid = "Near To Fit";
      } else if (actual > 90 && actual <= 100) {
        color = "#0D9859";
        this.labelMid = "Competency Fit";
      } else if (actual > 100) {
        color = "#008046";
        this.labelMid = "Above Standard";
      }
      var options = {
        series: series,
        chart: {
          width: 200,
          type: "pie",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        colors: ["#EDF1F0", color],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      };

      var chart = new ApexCharts(
        document.querySelector("#pieMidline"),
        options
      );
      chart.render();
    },
    pieEndline() {
      let actual = this.percent.endline;
      let rounding = actual > 100 ? 100 : actual;
      let series = [100 - rounding, rounding];
      let color = "";
      if (actual <= 60) {
        color = "#92C3AC";
        this.labelEnd = "Critical";
      } else if (actual > 60 && actual <= 75) {
        color = "#5FB48B";
        this.labelEnd = "Intensive Development";
      } else if (actual > 75 && actual <= 90) {
        color = "#489D74";
        this.labelEnd = "Near To Fit";
      } else if (actual > 90 && actual <= 100) {
        color = "#0D9859";
        this.labelEnd = "Competency Fit";
      } else if (actual > 100) {
        color = "#008046";
        this.labelEnd = "Above Standard";
      }
      var options = {
        series: series,
        chart: {
          width: 200,
          type: "pie",
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        colors: ["#EDF1F0", color],

        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
      };

      var chart = new ApexCharts(
        document.querySelector("#pieEndline"),
        options
      );
      chart.render();
    },
  },
};
</script>

<style scoped>
._summary {
  width: 100%;
}
.top-tri {
  width: 40%;
}
.activity_ {
  width: 33%;
}
.temp_radar {
  position: relative;
  height: 100%;
}
.my-progress {
  width: 100%;
}
.bg-radar {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.left__ {
  margin-left: 20%;
}
.relative {
  position: relative;
}
.inset_ {
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
.small-p {
  font-size: small;
}
._rank {
  width: calc(100% / 3);
  position: relative;
}
.temp-top3 {
  position: relative;
  box-sizing: border-box;
}
.bottom {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.rank3 {
  width: 50px;
  height: 20px;
  background: #008448;
}
.rank2 {
  width: 50px;
  height: 40px;
  background: #008448;
}
.rank1 {
  width: 50px;
  height: 70px;
  background: #008448;
}
.green-line {
  width: 100%;
  height: 5px;
  background: #008448;
}
</style>
