<template>
  <div class="d-flex" style="width: 100%">
    <div class="_summary">
      <div class="summary pa-3" v-if="counter">
        <v-row>
          <v-col cols="12" md="4">
            <v-card class="d-flex py-2 px-3 rounded-lg" height="120px" flat>
              <div class="d-flex align-center mx-3">
                <v-btn fab depressed color="#F3E5F5">
                  <v-icon color="#9C27B0">mdi-account-tie-outline</v-icon>
                </v-btn>
              </div>
              <div>
                <h1>{{ counter.data_control }}</h1>
                <p class="grey--text mt-n2">Total Data Control</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="d-flex py-2 px-3 rounded-lg" height="120px" flat>
              <div class="d-flex align-center mx-3">
                <v-btn fab depressed color="#E3F2FD ">
                  <v-icon color="#2196F3"
                    >mdi-chart-timeline-variant-shimmer</v-icon
                  >
                </v-btn>
              </div>
              <div>
                <h1>{{ counter.data_control_active }}</h1>
                <p class="grey--text mt-n2">Active Data Control</p>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="d-flex py-2 px-3 rounded-lg" height="120px" flat>
              <div class="d-flex align-center mx-3">
                <v-btn fab depressed color="#FBE9E7">
                  <v-icon color="#FF5722">mdi-poll</v-icon>
                </v-btn>
              </div>
              <div>
                <h1>{{ counter.data_control_non_active }}</h1>
                <p class="grey--text mt-n2">Unacitve Data Control</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="pa-3" v-if="loadingCount">
        <v-row>
          <v-col cols="12" md="4" v-for="i in 3" :key="i">
            <v-skeleton-loader height="120px" type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>

      <v-card class="rounded-lg mx-3" flat>
        <h3 class="pa-2">Statistic Activity</h3>
        <v-skeleton-loader
          v-if="loading"
          width="100%"
          type="image"
        ></v-skeleton-loader>
        <div v-if="log">
          <div v-if="log.data.length">
            <div id="activitySummary"></div>
          </div>
          <div v-if="!log.data.length" class="pa-3">
            <h3>No Chart Available</h3>
          </div>
        </div>
      </v-card>
    </div>
    <div class="activity_ pa-3">
      <div v-if="log">
        <h3 class="pa-2" v-if="log.data.length">Recent Activity</h3>

        <h3 v-if="!log.data.length">No Recent Activity</h3>
        <v-card
          class="pa-2 mb-3 rounded-lg"
          v-for="(item, i) in sevenLog"
          :key="i"
          flat
        >
          <v-row>
            <v-col cols="12" md="12" lg="3">
              <v-card
                flat
                color="blue lighten-5"
                class="d-flex flex-column align-center"
              >
                <v-icon large color="blue">mdi-calendar</v-icon>
                <small class="grey--text">{{
                  $date(item.created_at).format("DD/MM/YYYY")
                }}</small>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" lg="6" class="d-flex align-center">
              <div>
                <small>{{ item.description }}</small>
              </div>
            </v-col>
            <v-col cols="12" md="12" lg="3" class="d-flex align-center">
              <div class="d-flex align-center">
                {{ $date(item.created_at).fromNow() }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-if="loadingLog">
        <v-card v-for="i in 7" :key="i" class="mb-3 rounded-lg" flat>
          <v-skeleton-loader
            type="list-item-avatar-two-line"
          ></v-skeleton-loader>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ApexCharts from "apexcharts";
import { mapState } from "vuex";

export default {
  name: "dashboardAdmin",
  computed: {
    ...mapState({
      counter: (state) => state.user.userCounter,
      log: (state) => state.log.log,
    }),
    sevenLog() {
      let data = null;
      if (this.log) {
        // eslint-disable-next-line no-unused-vars
        data = this.log.data.filter((el, idx) => {
          return idx < 7;
        });
      }
      return data;
    },
  },
  data() {
    return {
      loading: true,
      loadingCount: false,
      loadingLog: false,
      isChartAvailable: null,
    };
  },
  mounted() {
    // setTimeout(() => this.chartSummary(), 1000);
    this.countAccount();
    this.getLog();
  },
  methods: {
    getLog() {
      let data = {
        page: 1,
        limit: 500,
      };
      this.loadingLog = true;
      this.$store.dispatch("log/listLog", data).then((data) => {
        this.loadingLog = false;
        let counter = 0;
        let obj = {};
        let act = data.data.data;
        for (let i = 0; i < act.length; i++) {
          counter++;
          if (i !== act.length - 1) {
            if (
              this.$date(act[i].created_at).format("DD MMM YYYY") !=
              this.$date(act[i + 1].created_at).format("DD MMM YYYY")
            ) {
              obj[this.$date(act[i].created_at).format("DD MMM")] = counter;
              counter = 0;
            }
          } else {
            obj[this.$date(act[i].created_at).format("DD MMM")] = counter;
          }
        }
        let attr = [];
        let val = [];
        for (let key in obj) {
          attr.push(key);
          val.push(obj[key]);
        }
        let dataChart = {
          key: attr,
          val: val,
        };
        setTimeout(() => this.chartSummary(dataChart), 500);
        this.loading = false;
      });
    },
    countAccount() {
      this.loadingCount = true;
      this.$store.dispatch("user/countUser", "data_control").then(() => {
        this.loadingCount = false;
      });
    },
    chartSummary(item) {
      var options = {
        chart: {
          height: 400,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            name: "Activity",
            data: item.val,
          },
        ],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        xaxis: {
          categories: item.key,
        },
      };

      var chart = new ApexCharts(
        document.querySelector("#activitySummary"),
        options
      );
      chart.render();
      this.loading = false;
    },
  },
};
</script>

<style scoped>
._summary {
  width: 60%;
}
.activity_ {
  width: 40%;
}
</style>
