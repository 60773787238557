<template>
  <div class="pa-3">
    <DashboardSuperamin v-if="role == 'SUPERADMIN' || role == 'ADMIN_TF'" />
    <DashboardDataControl v-if="role == 'DATA_CONTROL'" />
    <DashboardAssesment v-if="role == 'ADMIN_ASSESSMENT'" />
    <DashboardScholar v-if="role == 'SCHOLAR'" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import DashboardAssesment from "./Assessment/dashboardAssesment.vue";
import DashboardDataControl from "./dataControl/dashboardDataControl.vue";
import DashboardScholar from "./Scholar/dashboardScholar.vue";
import DashboardSuperamin from "./Superadmin/dashboardSuperadmin.vue";
export default {
  components: {
    DashboardSuperamin,
    DashboardAssesment,
    DashboardScholar,
    DashboardDataControl,
  },
  name: "mainDAshboard",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
};
</script>

<style>
</style>