<template>
  <div>
    <section v-if="loading">
      <LoadingLg />
    </section>
    <section v-if="!loading" class="d-flex">
      <div class="left">
        <v-row v-if="gender">
          <v-col cols="12" md="6">
            <v-card height="150px" flat class="pa-3">
              <div class="d-flex align-center">
                <v-btn
                  class="mr-3"
                  fab
                  depressed
                  x-large
                  color="blue lighten-5"
                >
                  <v-icon color="blue">mdi-gender-male</v-icon>
                </v-btn>
                <h3 class="grey--text">Male Scholar</h3>
              </div>
              <div>
                <h1 class="text-center">
                  {{ gender["laki-laki"] ? gender["laki-laki"] : 0 }}
                </h1>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card height="150px" flat class="pa-3">
              <div class="d-flex align-center">
                <v-btn
                  class="mr-3"
                  fab
                  depressed
                  x-large
                  color="pink lighten-5"
                >
                  <v-icon color="pink">mdi-gender-female</v-icon>
                </v-btn>
                <h3 class="grey--text">Female Scholar</h3>
              </div>
              <div>
                <h1 class="text-center">
                  {{ gender.perempuan ? gender.perempuan : 0 }}
                </h1>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div class="mt-5">
          <v-card flat class="pa-3" height="auto">
            <div class="d-flex justify-space-between">
              <h3>Scholar Demographics By Religion</h3>
            </div>
            <div class="d-flex justify-center mt-3" v-if="optReligion">
              <div v-if="optReligion.series.length">
                <apexchart
                  width="350"
                  type="pie"
                  :options="optReligion.options"
                  :series="optReligion.series"
                ></apexchart>
              </div>
              <div
                class="d-flex justify-center"
                v-if="!optReligion.series.length"
              >
                <div>
                  <img
                    src="../../../assets/img/V.1 110921 No Chart (no text)0.png"
                    height="200px"
                    alt=""
                  />
                  <h3 class="text-center" style="color: #c8e6c9">
                    No Chart Available
                  </h3>
                </div>
              </div>
            </div>
            <div class="d-flex justify-center mt-3" v-else>Memuat...</div>
          </v-card>
        </div>
        <div class="mt-5">
          <v-card flat class="pa-3" height="auto">
            <div class="d-flex justify-space-between">
              <h3>Scholar Demographics By Ethnic</h3>
            </div>
            <div class="d-flex justify-center mt-3" v-if="optEthnic">
              <div v-if="optEthnic.series.length">
                <apexchart
                  width="380"
                  height="500"
                  type="donut"
                  :options="optEthnic.options"
                  :series="optEthnic.series"
                ></apexchart>
              </div>
              <div
                class="d-flex justify-center"
                v-if="!optEthnic.series.length"
              >
                <div>
                  <img
                    src="../../../assets/img/V.1 110921 No Chart (no text)0.png"
                    height="200px"
                    alt=""
                  />
                  <h3 class="text-center" style="color: #c8e6c9">
                    No Chart Available
                  </h3>
                </div>
              </div>
            </div>
            <div class="d-flex justify-center mt-3" v-else>Memuat...</div>
          </v-card>
        </div>
      </div>
      <div class="right">
        <div class="ml-5">
          <v-select
            class="mb-3"
            dense
            background-color="#C8E6C9"
            hide-details
            :items="getYear"
            item-text="state"
            item-value="abbr"
            v-model="filterYear"
            @change="filtersYear()"
            label="Filter Year"
            flat
            solo
          ></v-select>
          <v-card flat class="pa-3" height="auto">
            <div class="d-flex justify-space-between">
              <h3>Number of Scholars Per Year Force</h3>
            </div>
            <div id="year" v-if="optYear">
              <apexchart
                height="300"
                type="area"
                :options="optYear.options"
                :series="optYear.series"
              ></apexchart>
            </div>
            <div class="d-flex justify-center mt-3" v-else>Memuat...</div>
          </v-card>
        </div>
        <div class="ml-5 mt-5">
          <v-card flat class="pa-3">
            <div class="d-flex justify-space-between">
              <h3>Scholar Demographics By University</h3>
            </div>
            <div id="univ" v-if="optUniv">
              <apexchart
                height="300"
                type="bar"
                :options="optUniv.options"
                :series="optUniv.series"
              ></apexchart>
            </div>
            <div class="d-flex justify-center mt-3" v-else>Memuat...</div>
          </v-card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import ApexCharts from "apexcharts";
import { mapState } from "vuex";
import LoadingLg from "../../../components/etc/loading-component/loadingLG.vue";
export default {
  components: { LoadingLg },
  name: "dashboardDatacontrol",
  computed: {
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2019; i <= year; i++) {
        data.push({
          state: i,
          abbr: i,
        });
      }
      data.push({
        state: "All years",
        abbr: "",
      });
      return data;
    },
    ...mapState({
      gender: (state) => state.grafis.s_gender,
    }),
  },
  data() {
    return {
      category: ["tahun_tf", "jenis_kelamin", "etnis", "universitas", "agama"],
      loading: false,
      process: "",
      filterYear: "",
      optReligion: null,
      optEthnic: null,
      optYear: null,
      optUniv: null,
    };
  },
  mounted() {
    this.category.forEach((item) => {
      setTimeout(() => {
        this.getInfografis(item, "", true);
      }, 200);
    });
  },
  methods: {
    getInfografis(category, year, load) {
      this.process = category;
      let data = {
        category: category,
        year: year,
      };
      if (load) {
        this.loading = true;
      }
      this.$store.dispatch("grafis/scholarInfografis", data).then((data) => {
        if (category == "universitas") {
          this.chartUniv(data);
        } else if (category == "tahun_tf") {
          this.chartYear(data);
        } else if (category == "agama") {
          this.chartReligion(data);
        } else if (category == "etnis") {
          this.chartEtnis(data);
        }
        this.loading = false;
      });
    },
    filtersYear() {
      this.category.forEach((item) => {
        setTimeout(() => {
          this.getInfografis(item, this.filterYear, false);
        }, 200);
      });
    },
    chartYear(data) {
      let attr = [];
      let val = [];
      for (let key in data) {
        attr.push(key);
        val.push(data[key]);
      }
      let s_obj = {
        key: attr,
        val: val,
      };
      let series = [
        {
          name: "scholar",
          data: s_obj.val,
        },
      ];
      let options = {
        chart: {
          id: "area",
          toolbar: {
            show: false,
          },
        },

        markers: {
          size: 5,
        },
        colors: ["#008448"],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: s_obj.key,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
        ],
      };

      this.optYear = {
        options: options,
        series: series,
      };
    },
    chartUniv(data) {
      let attr = [];
      let val = [];
      for (let key in data) {
        switch (key) {
          case "institut teknologi bandung":
            attr.push("ITB");
            break;
          case "institut pertanian bogor":
            attr.push("IPB");
            break;
          case "universitas pendidikan indonesia":
            attr.push("UPI");
            break;
          case "universitas gadjah mada":
            attr.push("UGM");
            break;
          case "universitas andalas":
            attr.push("UNAND");
            break;
          case "universitas brawijaya":
            attr.push("UB");
            break;
          case "universitas diponegoro":
            attr.push("UNDIP");
            break;
          case "universitas indonesia":
            attr.push("UI");
            break;
          case "universitas sumatera utara":
            attr.push("USU");
            break;
          case "universitas riau":
            attr.push("UNRI");
            break;
          default:
            attr.push(key);
            break;
        }
        val.push(data[key]);
      }
      let s_obj = {
        key: attr,
        val: val,
      };
      let options = {
        chart: {
          id: "bar",
          toolbar: {
            show: false,
          },
        },
        colors: ["#008448"],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 3,
          colors: ["transparent"],
        },
        xaxis: {
          categories: s_obj.key,
        },
        fill: {
          opacity: 1,
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
            },
          },
        ],
      };

      let series = [
        {
          name: "scholar",
          data: s_obj.val,
        },
      ];

      this.optUniv = {
        options: options,
        series: series,
      };
    },
    chartReligion(data) {
      let attr = [];
      let val = [];
      for (let key in data) {
        attr.push(key);
        val.push(data[key]);
      }
      let s_obj = {
        key: attr,
        val: val,
      };
      let options = {
        chart: {
          id: "vuechart-example",
        },
        labels: s_obj.key,
      };
      let series = s_obj.val;

      this.optReligion = {
        options: options,
        series: series,
      };
    },
    chartEtnis(data) {
      let attr = [];
      let val = [];
      for (let key in data) {
        attr.push(key);
        val.push(data[key]);
      }
      let s_obj = {
        key: attr,
        val: val,
      };
      let options = {
        chart: {
          id: "donut",
        },
        labels: s_obj.key,
      };
      let series = s_obj.val;

      this.optEthnic = {
        options: options,
        series: series,
      };
    },
  },
};
</script>

<style scoped>
.left {
  width: 50%;
}
.right {
  width: 50%;
}
</style>